<template>
  <div>
    <div class="title">{{$t('titleI18n.BaseInfo')}}</div>
    <el-form ref="formInfo" :model="formInfo" label-width="210px">
      <el-form-item :label="$t('formTitleI18n.BusinessType')" prop="businessType">
        <span>{{ formInfo.businessType }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.CoreUserName')" prop="payerName">
        <span>{{ formInfo.payerName }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.OrderAmount')" prop="amount">
        <span>{{ formInfo.amount | toThousandFilterTwo }}</span>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.OrderTime')" prop="dueDate">
        <span>{{ formInfo.dueDate }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.ProductType')"
        prop="desctiption"
        style="width: 100%;"
      >
        <span>{{ formInfo.desctiption }}</span>
      </el-form-item>

      <div class="title">{{$t('titleI18n.InvoiceInfo')}}</div>
      <el-form-item :label="$t('formTitleI18n.InvoiceUpload')" prop="invoice" style="width: 100%;">
        <div v-for="(item,index) in JSON.parse(formInfo.invoice)" :key="index">
          <el-link
              class="link-style"
              :underline="false"
              type="primary"
              @click="goLink(item)"
          >{{ item.split("/")[item.split("/").length - 1] }}</el-link>
        </div>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceNum')" prop="invoiceCode">
        <span>{{ formInfo.invoiceCode }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceAmount')" prop="invoiceValue">
        <span>{{ formInfo.invoiceValue | toThousandFilterTwo }}</span>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>

      <div
        class="title"
        v-if="formInfo.contractNumber || formInfo.contractName || formInfo.contractAmount || formInfo.contractValid"
      >{{$t('titleI18n.ContractInfo')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.ContractNum')"
        prop="contractNumber"
        v-if="formInfo.contractNumber"
      >
        <span>{{ formInfo.contractNumber }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.ContractName')"
        prop="contractName"
        v-if="formInfo.contractName"
      >
        <span>{{ formInfo.contractName }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.ContractAmount')"
        prop="contractAmount"
        v-if="formInfo.contractAmount"
      >
        <span>{{ formInfo.contractAmount | toThousandFilterTwo }}</span>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.ContractTime')"
        prop="contractValid"
        v-if="formInfo.contractValid"
      >
        <span>{{ formInfo.contractValid | timeStampToDate }}</span>
      </el-form-item>

      <div class="title">{{$t('titleI18n.RepayType')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.RepayType')"
        prop="contractPaymentMode"
        :label-width="language === 'zh-cn' ? '210px' : '300px'"
      >
        <span>{{ formInfo.contractPaymentMode }}</span>
      </el-form-item>

      <div class="title">{{$t('titleI18n.TicketInfo')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.TicketUpload')"
        prop="contractDetail"
        style="width: 100%;"
      >
        <div v-for="(item,index) in JSON.parse(formInfo.contractDetail)" :key="index">
          <el-link
              class="link-style"
              :underline="false"
              type="primary"
              @click="goLink(item)"
          >{{ item.split("/")[item.split("/").length - 1] }}</el-link>
        </div>
      </el-form-item>
    </el-form>
    <div class="flex-row-center">
      <el-button class="btn-black" @click="toList">{{$t('btnI18n.Close')}}</el-button>
    </div>
  </div>
</template>

<script>
import filter from "@/filters";

export default {
  name: "assets-info",

  data() {
    return {
      formInfo: {
        businessType: "",
        contractAmount: "",
        contractName: "",
        contractNumber: "",
        contractValid: "",
        payerName: "",
        invoiceValue: "",
        invoiceCode: "",
        invoice: "",
        amount: "",
        dueDate: "",
        desctiption: "",
        contractPaymentMode: "",
        contractDetail: ''
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    goLink(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
          window.open(url);
      }
    },
    getDetail() {
      let _this = this;
      if (!this.$route.query.id) return false;
      this.$axios.get("/v1/supplier/receivable-order", { params: { id: this.$route.query.id } }).then(result => {
        if (result) {
          _this.formInfo = result.data;
          _this.formInfo.businessType = filter.filterBusinessType(_this.formInfo.businessType);
          _this.formInfo.contractPaymentMode = filter.filterRepayType(_this.formInfo.contractPaymentMode);
          _this.formInfo.amount = Number(_this.formInfo.amount) / this.$enums.UNIT_MILLION;
          _this.formInfo.invoiceValue = Number(_this.formInfo.invoiceValue) / this.$enums.UNIT_MILLION;
          if (_this.formInfo.contractAmount) {
            _this.formInfo.contractAmount = Number(_this.formInfo.contractAmount) / this.$enums.UNIT_MILLION;
          }
        }
      });
    },
    toList() {
      this.$router.push({ path: "/assets-list" });
    },
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style scoped lang="scss">
.el-form-item {
  width: 550px;
  display: inline-block;
}
.check-example {
  margin-left: 15px;
}
</style>
